import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useSelectedAdvertiser } from "../../contexts/selectedAdvertiser";
import { useAdvertiser, useAllAdvertisers } from "../../services/advertiser";

import { Selection } from "../../components/select";
import { useToggleSidebar } from "../../contexts/toggleSidebar";

const SidebarLogo = () => {
  const navigateTo = useNavigate();

  const { isSidebarOpen } = useToggleSidebar();

  const { selectedAdvertiser, setSelectedAdvertiser } = useSelectedAdvertiser();

  const { data: advertisersData, isPending: isAdvertisersPending } =
    useAllAdvertisers();
  const {
    data: selectedAdvertiserData,
    isPending: isSelectedAdvertiserDataPending,
    isSuccess: isSelectedAdvertiserDataSuccess,
  } = useAdvertiser(selectedAdvertiser);

  useEffect(() => {
    if (
      selectedAdvertiser &&
      !selectedAdvertiserData &&
      !isSelectedAdvertiserDataPending
    ) {
      localStorage.removeItem("advertiser");
      setSelectedAdvertiser(null);
    }

    // // if advertisers are present but no advertiser is selected, select the first advertiser by default
    if (advertisersData?.length !== 0 && !selectedAdvertiser) {
      setSelectedAdvertiser(advertisersData?.[0].id ?? null);
    }

    //eslint-disable-next-line
  }, [selectedAdvertiserData, advertisersData, selectedAdvertiser]);

  const AdvertiserSelector = () => {
    const selectionData = advertisersData?.map((item: any) => ({
      id: item.id,
      value: item.displayName,
      label: item.displayName,
    }));
    return (
      <Selection
        placeholder={
          selectedAdvertiserData?.displayName
            ? selectedAdvertiserData.displayName
            : isSelectedAdvertiserDataPending
            ? ""
            : "Switch Advertiser"
        }
        data={selectionData}
        value={selectedAdvertiserData?.displayName}
        isPending={isAdvertisersPending}
        closeMenuOnSelect={true}
        isMulti={undefined}
        customStyle={{
          selectContainer: {
            display: `${isSidebarOpen ? "flex" : "none !important"}`,
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: "#E5EBEE",
            borderRadius: "0.25rem",
            width: "100%",
            fontSize: 14,
            fontFamily: "InterRegular",
          },
          valueContainer: {
            paddingInline: "0.75rem",
            paddingBlock: "0",
          },
          color: "#d3d3d3",
          optionisSelectedBgColor: "transparent",
          optionisFocusedBgColor: "#d7edff",
          optionisFocusedTextColor: "#0e73f6",
          singleValStyles: {
            backgroundColor: "transparent",
          },
        }}
        onChange={(selectedOption) => setSelectedAdvertiser(selectedOption.id)}
      />
    );
  };

  const NoAdvertisersMsg = () => (
    <p
      className={` ${
        isSidebarOpen ? "block" : "hidden"
      } font-ManropeSemiBold text-center`}
    >
      No available advertiser. Please visit{" "}
      <span
        className=" cursor-pointer font-ManropeExtraBold text-[#5972A5]"
        onClick={() => navigateTo("/advertiser")}
      >
        Advertisers
      </span>{" "}
      page and create one.
    </p>
  );

  // const NoCampaignPhotoMsg = () => (
  //   <div className="border border-dashed p-10">
  //     <p className=" font-interRegular text-sm text-center">
  //       No available campaign yet.
  //     </p>
  //   </div>
  // );

  const LogoSkeleton = () => (
    <div className="flex justify-center items-center">
      <div className=" animate-pulse h-[5.5rem]  w-[5.5rem] bg-gray-200  rounded-full  "></div>
    </div>
  );

  const AdvertiserLogo = () => (
    <div className=" w-full flex items-center justify-center">
      {isSelectedAdvertiserDataSuccess && selectedAdvertiser ? (
        <img
          src={selectedAdvertiserData?.photoUrl}
          className=" object-contain h-[7rem] w-[9rem]"
          alt={`${selectedAdvertiserData.displayName}.png`}
        />
      ) : (
        <LogoSkeleton />
      )}
    </div>
  );

  return (
    <div className="bg-white flex  w-full xs:mt-20 md:mt-6 justify-center rounded-lg flex-col space-y-5">
      {advertisersData?.length === 0 ? (
        <NoAdvertisersMsg />
      ) : (
        <>
          <AdvertiserLogo />
          <AdvertiserSelector />
        </>
      )}
    </div>
  );
};

export default SidebarLogo;
