import PieChart from "../../../../components/pieChart";

import { IBudgetOrganizationChannelsResponse } from "../../../../services/advertiser/organization-budget-channel";
import { ICampaignStrategyResponse } from "../../../../services/campaigns";

import { formatDate } from "../../../../utils/formatDate";

import { BUDGET_CHANNEL_MAPPING_READ_MODE } from "../../../../constants/wizard/campaign";

const CampaignStrategyReadMode = ({
  campaignStrategyData,
  organizationBudgetChannels,
}: {
  campaignStrategyData: ICampaignStrategyResponse | undefined;
  organizationBudgetChannels:
    | []
    | IBudgetOrganizationChannelsResponse[]
    | undefined;
}) => {
  return (
    <div className=" flex mt-7">
      <div className="flex flex-col w-[65%]">
        <section id="strategy-summary">
          <h3 className="text-primary font-interSemiBold text-sm">Summary</h3>
          <p className="text-sm text-primary mt-1.5 font-interRegular">
            Your campaign
            <span className="font-interSemiBold mx-1.5">
              {campaignStrategyData?.campaignName}
            </span>
            is designed to achieve a 30 day budget goal of
            <span className="font-interSemiBold mx-1.5">
              ${campaignStrategyData?.campaignBudget.budget}
            </span>
            to drive the goal of
            <span className="font-interSemiBold mx-1.5">
              {campaignStrategyData?.campaignGoal?.value}
            </span>
            . Your budget was determined using a typical allocation, assuming an
            annual revenue of $100,000.
          </p>
          <p className="text-sm text-primary mt-6 font-interRegular">
            The Campaign will run from
            <span className="font-interSemiBold mx-1.5">
              {campaignStrategyData?.campaignStartDate?.value
                ? formatDate(campaignStrategyData?.campaignStartDate?.value)
                : "null date"}
            </span>
            to
            <span className="font-interSemiBold mx-1.5">
              {campaignStrategyData?.campaignEndDate?.value
                ? formatDate(campaignStrategyData?.campaignEndDate?.value)
                : "null date"}
            </span>
            with a central theme of
            <span className="font-interSemiBold mx-1.5">
              {campaignStrategyData?.campaignTheme?.value ?? null}
            </span>
            , which will shape the messaging and outreach effort to boost
            engagement and impact.
          </p>
          <p className="text-sm text-primary mt-6 font-interRegular">
            Feel free to adjust these settings anytime by clicking on “Edit”
          </p>
        </section>
        <section id="strategy-allocation" className="mt-10">
          <h3 className="text-primary font-interSemiBold text-sm mb-1.5">
            Allocation
          </h3>
          <p className="text-sm text-primary font-interRegular">
            Your campaign budget has been allocated strategically to maximize
            impact and ensure optimal reach to your target audience while
            maintaining cost efficiency.
          </p>
          <div className="flex mt-6">
            {campaignStrategyData && organizationBudgetChannels && (
              <PieChart
                topContainer="flex flex-col space-y-5 w-[3rem] order-2"
                indicatorContainer="flex items-center w-36"
                circleContainer="relative w-[25rem]"
                data={organizationBudgetChannels.map((channel) => {
                  const channelKey = BUDGET_CHANNEL_MAPPING_READ_MODE[
                    channel.budgetChannel
                  ].key as keyof ICampaignStrategyResponse["campaignBudget"];

                  const channelColor =
                    BUDGET_CHANNEL_MAPPING_READ_MODE[channel.budgetChannel]
                      .color;
                  const value = campaignStrategyData.campaignBudget[channelKey];

                  return {
                    key: `campaign-strategy-pie-${channelKey}`,
                    value: value,
                    color: channelColor,
                    title: channel.channelName,
                  };
                })}
              />
            )}
          </div>
        </section>
      </div>

      <div className="w-[30%] ml-auto">
        <div className="w-full rounded-md border-solid border border-[#E5E9EB]">
          <div className="py-3 border-b border-solid border-[#E5E9EB] rounded-md ">
            <p className="text-primary text-sm font-interRegular text-center">
              {campaignStrategyData?.campaignGoal.value === "Traffic"
                ? "Estimated Visitors on Page"
                : "Estimated views"}
            </p>
          </div>

          <div className="flex">
            <div className="text-center w-1/2 mr-0.5 border rounded-md py-6 border-solid border-[#E5E9EB]">
              <p className=" text-colorGreen text-4xl font-interRegular">
                {campaignStrategyData?.campaignBudgetEstimation.high}
              </p>
              <p className="text-colorGreen text-base font-interRegular">
                HIGH
              </p>
            </div>
            <div className="text-center w-1/2  border rounded-md py-6 border-solid border-[#E5E9EB]">
              <p className="text-colorDelete text-4xl font-interRegular">
                {campaignStrategyData?.campaignBudgetEstimation.low}
              </p>
              <p className="text-colorDelete text-base font-interRegular">
                LOW
              </p>
            </div>
          </div>

          <div className=" border py-2 px-1 border-solid border-[#E5E9EB] rounded-md ">
            <p className="text-xs text-primary font-interRegular">
              These estimates are based on industry averages and may vary due to
              factors like seasonality, competition, and location.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignStrategyReadMode;
