import React, { useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { useAuth } from "../contexts/auth";

import { axios } from "../services/axios";

import NavigationListener from "../components/navigationListener";

import Campaign from "../pages/campaigns";
import CampaignDetails from "../pages/campaigns/campaignDetails";
import Wizard from "../pages/wizard/index";
import Profile from "../pages/profile";
import PrivateRoute from "../pages/privateRoute";
import Login from "../pages/login";
import SignUpPage from "../pages/signUp";
import Websites from "../pages/websites";
import WebsiteDetails from "../pages/websites/websiteDetails";
import WebsiteAssets from "../pages/websites/websiteAssets";
import AddAsset from "../pages/websites/websiteAssets/addAsset";
import AddWebSite from "../pages/websites/addWebsite";
import ForgetPassword from "../pages/forgotPassword";
import Advertiser from "../pages/advertiser";
import AddAdvertiser from "../pages/advertiser/addAdvertiser";
import SingleAdvertiser from "../pages/advertiser/editAdvertiser";
import AdvertiserBusinessProfile from "../pages/advertiser/businessProfile";
import Insights from "../pages/insights";
import Notifications from "../pages/notifications";
import Help from "../pages/help";

import { ScrollToTop } from "../utils/scrollToTop";
import { AddSiteProvider } from "../contexts/addWebsite";

export const RootNavigator = () => {
  const {
    user,
    tokenRefreshed,
    setFirebaseTokenAsAxiosAuthToken,
    clearAuthStates,
  } = useAuth();

  useEffect(() => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        const tokenExpired =
          error?.response?.data?.error?.moreInfo?.code ===
          "auth/id-token-expired";
        tokenExpired && setFirebaseTokenAsAxiosAuthToken(true);
        return Promise.reject(error);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <NavigationListener clearAuthStates={clearAuthStates} />
      <Routes>
        <Route
          path="/signin"
          element={user ? <Navigate to="/" /> : <Login />}
        />
        <Route
          path="/reset-password"
          element={user ? <Navigate to="/" /> : <ForgetPassword />}
        />
        <Route
          path="/signup"
          element={
            user && tokenRefreshed ? <Navigate to="/" /> : <SignUpPage />
          }
        />

        <Route path="/" element={<Navigate to="/campaigns" replace />} />

        <Route
          path="/campaigns"
          index
          element={
            <PrivateRoute>
              <Campaign />
            </PrivateRoute>
          }
        />
        <Route
          path="/campaigns/campaignDetails/:campaignId"
          element={
            <PrivateRoute>
              <CampaignDetails />
            </PrivateRoute>
          }
        />

        <Route
          path="/campaigns/wizard"
          element={
            <PrivateRoute>
              <Wizard />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          }
        />

        <Route
          path="/websites"
          element={
            <AddSiteProvider>
              <PrivateRoute>
                <Websites />
              </PrivateRoute>
            </AddSiteProvider>
          }
        />
        <Route
          path="/websites/metadata/:id"
          element={
            <PrivateRoute>
              <WebsiteDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="/websites/assets/:id"
          element={
            <PrivateRoute>
              <WebsiteAssets />
            </PrivateRoute>
          }
        />
        <Route
          path="/websites/assets/:id/addAsset"
          element={
            <PrivateRoute>
              <AddAsset />
            </PrivateRoute>
          }
        />
        <Route
          path="/websites/addWebsite"
          element={
            <AddSiteProvider>
              <PrivateRoute>
                <AddWebSite />
              </PrivateRoute>
            </AddSiteProvider>
          }
        />

        <Route
          path="/advertiser"
          element={
            <PrivateRoute>
              <Advertiser />
            </PrivateRoute>
          }
        />
        <Route
          path="/advertiser/addAdvertiser"
          element={
            <PrivateRoute>
              <AddAdvertiser />
            </PrivateRoute>
          }
        />
        <Route
          path="/advertiser/:id"
          element={
            <PrivateRoute>
              <SingleAdvertiser />
            </PrivateRoute>
          }
        />
        <Route
          path="/advertiser/businessProfile/:id"
          element={
            <PrivateRoute>
              <AdvertiserBusinessProfile />
            </PrivateRoute>
          }
        />
        <Route
          path="/insights"
          element={
            <PrivateRoute>
              <Insights />
            </PrivateRoute>
          }
        />
        <Route
          path="/notifications"
          element={
            <PrivateRoute>
              <Notifications />
            </PrivateRoute>
          }
        />
        <Route
          path="/help"
          element={
            <PrivateRoute>
              <Help />
            </PrivateRoute>
          }
        />

        <Route
          path="*"
          element={
            user ? (
              <Navigate replace to="/" />
            ) : (
              <Navigate replace to="/signin" />
            )
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default RootNavigator;
